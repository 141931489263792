type Props = {
  className?: string;
};

export default function LargeIndustriousLogo(props: Props) {
  const { className } = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="230"
      height="29"
      role="img"
      className={className || 'fill-teal-5'}
    >
      <title>Logo Industrious</title>
      <path
        fillRule="evenodd"
        d="m15.964 1.72-.665.884-.574.767a4.885 4.885 0 0 0-2.043-.767l.299-.396.665-.883.666-.883a2.5 2.5 0 0 1 .132-.176c.066-.084.135-.171.19-.266.366.046.71.093 1.17.21.202.347.333.66.455.951l.05.118c-.085.085-.152.18-.218.271-.041.059-.082.116-.127.17Zm1.377 9.043c1.125.418 3.328 2.417 4.338 4.626 1.193 2.58.78 7.926.688 8.484l-.092 1.534h-3.603c-2.662 0-3.488-2.673-3.74-4.625l-3.696 7.601-3.694-7.601c-.253 1.952-1.079 4.625-3.741 4.625H.198l-.092-1.464C.06 23.408-.353 17.97.84 15.39c1.01-2.185 3.213-4.185 4.338-4.626.183-4.696 2.891-6.44 5.53-6.44h1.102c2.64 0 5.347 1.767 5.531 6.44ZM5.545 18.132c0 2.952-.505 4.625-1.698 4.625H2.263c-.069-1.836-.046-4.765.597-6.136.482-1.046 1.24-2.278 1.882-2.906l.803 4.417Zm5.714-11.437h-.55c-1.515 0-3.03.79-3.374 3.696h7.849c-.321-2.906-1.86-3.696-3.374-3.696h-.55Zm0 16.504-1.675-3.324h3.374L11.259 23.2Zm3.512-6.485.436-3.162H7.335l.436 3.162h7Zm5.485 6.044h-1.56c-1.194 0-1.7-1.674-1.7-4.626l.804-4.417c.643.628 1.4 1.836 1.882 2.906.62 1.394.666 4.3.574 6.137ZM7.817 3.348l-.574-.744-.665-.86-.043-.061a2.76 2.76 0 0 0-.301-.381c.137-.349.275-.674.504-1.093.436-.11.789-.158 1.118-.202L7.909 0c.092.163.206.302.321.442l.666.86.665.883.299.395c-.712.093-1.4.349-2.043.767Z"
        clipRule="evenodd"
      />
      <path d="M34.255 6.176h4.157V22.23h-4.157V6.176ZM49.397 14.308v7.922h-3.832V5.966h.325l9.405 8.155V6.176h3.832v16.288h-.325l-9.405-8.156Z" />
      <path
        fillRule="evenodd"
        d="M71.969 6.176h-5.736v16.03h5.736c4.737 0 8.314-3.13 8.314-8.014 0-4.931-3.6-8.016-8.314-8.016Zm4.087 8.039c0 2.71-1.3 4.393-4.273 4.393h-1.37V9.822h1.37c2.95 0 4.273 1.729 4.273 4.393Z"
        clipRule="evenodd"
      />
      <path d="M86.901 15.897v-9.72h4.157v9.65c0 1.963.86 2.945 2.717 2.945 1.858 0 2.717-.958 2.717-2.945v-9.65h4.157v9.72c0 4.347-2.88 6.614-6.85 6.614-4.088 0-6.898-2.29-6.898-6.614ZM106.339 18.795l3.251-1.916c.674 1.168 1.672 2.08 3.321 2.08 1.138 0 1.951-.421 1.951-1.169 0-.77-.581-1.098-2.16-1.752l-.674-.28c-2.624-1.076-4.412-2.361-4.412-5.235 0-2.805 2.137-4.65 5.225-4.65 2.299 0 3.878.677 5.202 2.757l-3.158 1.823c-.558-.888-1.138-1.146-1.928-1.146-.836 0-1.37.421-1.37 1.146 0 .864.697 1.168 1.649 1.565l.673.28c2.927 1.192 5.063 2.408 5.063 5.446 0 2.99-2.253 4.743-6.015 4.743-3.228.024-5.573-1.542-6.618-3.692ZM128.517 9.798h-4.529V6.176h13.237v3.622h-4.528V22.23h-4.18V9.798Z" />
      <path
        fillRule="evenodd"
        d="M151.995 22.23h4.667l-3.042-5.024c1.719-.888 2.81-2.477 2.81-4.884 0-4.23-3.088-6.146-7.315-6.146h-5.411v16.03h4.157v-3.832h1.254c.232 0 .441 0 .65-.023l2.23 3.88Zm.185-9.908c0 1.823-.998 2.524-3.158 2.524h-1.161V9.775h1.161c2.183 0 3.158.724 3.158 2.547Z"
        clipRule="evenodd"
      />
      <path d="M163.234 6.176h4.157V22.23h-4.157V6.176Z" />
      <path
        fillRule="evenodd"
        d="M182.742 5.896c-4.691 0-8.593 3.365-8.593 8.296 0 4.86 3.902 8.319 8.593 8.295 4.691 0 8.592-3.435 8.592-8.295 0-4.908-3.924-8.296-8.592-8.296Zm0 3.692c2.578 0 4.342 1.893 4.342 4.604 0 2.78-1.764 4.603-4.342 4.603-2.578 0-4.343-1.846-4.343-4.604 0-2.687 1.765-4.603 4.343-4.603Z"
        clipRule="evenodd"
      />
      <path d="M197.929 15.897v-9.72h4.157v9.65c0 1.963.859 2.945 2.717 2.945s2.717-.958 2.717-2.945v-9.65h4.157v9.72c0 4.347-2.88 6.614-6.851 6.614-4.087 0-6.897-2.29-6.897-6.614ZM217.367 18.795l3.251-1.916c.673 1.168 1.672 2.08 3.321 2.08 1.138 0 1.95-.421 1.95-1.169 0-.77-.58-1.098-2.159-1.752l-.674-.28c-2.624-1.076-4.412-2.361-4.412-5.235 0-2.805 2.136-4.65 5.225-4.65 2.299 0 3.878.677 5.202 2.757l-3.158 1.823c-.558-.888-1.138-1.146-1.928-1.146-.836 0-1.37.421-1.37 1.146 0 .864.697 1.168 1.649 1.565l.673.28c2.926 1.192 5.063 2.408 5.063 5.446 0 2.99-2.253 4.743-6.015 4.743-3.228.024-5.573-1.542-6.618-3.692Z" />
    </svg>
  );
}
