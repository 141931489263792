import Image from 'next/image';
import Link from 'next/link';

import VerticalLogo from '@/public/icons/verticalLogoWhiteIcon.svg';

import StaticLinksSection from './StaticLinksSection';

export default function StaticFooter() {
  return (
    <footer className="text-white bg-teal-40 py-14">
      <div className="base-container">
        <div className="grid grid-cols-2 gap-x-5 gap-y-10 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5">
          <StaticLinksSection
            title="Industrious"
            items={[
              {
                href: '/about-us',
                dataTestId: 'link-about',
                label: 'About',
              },
              {
                href: '/locations',
                dataTestId: 'link-locations',
                label: 'Locations',
              },
              {
                href: '/about-us#faqs',
                dataTestId: 'link-faq',
                label: "FAQ's",
              },
              {
                href: '/about-us#team',
                dataTestId: 'link-team',
                label: 'Team',
              },
              { href: '/blog', dataTestId: 'link-blog', label: 'Blog' },
            ]}
          />
          <StaticLinksSection
            title={'Solutions'}
            items={[
              {
                href: '/solutions/offices',
                dataTestId: 'link-offices',
                label: 'Offices',
              },
              {
                href: '/solutions/suites',
                dataTestId: 'link-suites',
                label: 'Suites',
              },
              {
                href: '/solutions/coworking-membership',
                dataTestId: 'link-access',
                label: 'Access',
              },
              {
                href: '/solutions/virtual',
                dataTestId: 'link-virtual',
                label: 'Virtual',
              },
              {
                href: '/solutions/conference-and-meeting-rooms',
                dataTestId: 'link-meetings',
                label: 'Meetings',
              },
            ]}
          />
          <StaticLinksSection
            title={'Partnerships'}
            items={[
              {
                href: '/brokers',
                dataTestId: 'broker',
                label: 'Brokers',
              },
              {
                href: '/enterprise',
                dataTestId: 'link-enterprise',
                label: 'Enterprise',
              },
              {
                href: '/landlords',
                dataTestId: 'link-partnerships',
                label: 'Landlords',
              },
            ]}
          />
          <StaticLinksSection
            title={'Connect'}
            items={[
              {
                href: '/contact-us',
                dataTestId: 'contact-us',
                label: 'Contact',
              },
              {
                href: '/contact-us?prMediaForm=open',
                dataTestId: 'link-press',
                label: 'Press',
              },
              {
                href: '/careers',
                dataTestId: 'link-careers',
                label: 'Careers',
              },
            ]}
          />
          <StaticLinksSection
            title={'Members'}
            items={[
              {
                href: 'https://portal.industriousoffice.com',
                dataTestId: 'link-signin',
                label: 'Sign In',
                isExternal: true,
              },
              {
                href: 'https://apps.apple.com/us/app/industrious-office/id1571362704',
                dataTestId: 'link-download-ios',
                label: 'Download iOS App',
                isExternal: true,
              },
              {
                href: 'https://play.google.com/store/apps/details?id=com.industriousoffice.app',
                dataTestId: 'link-download-android',
                label: 'Download Android App',
              },
            ]}
          />
        </div>
        <div className="flex flex-col items-center gap-y-8 lg:flex-row lg:items-end justify-between mt-[48px] sm:mt-[70px]">
          <Link className="pb-[5px] lg:mb-0" href="/" data-testid="footer-logo">
            <Image src={VerticalLogo} alt="Industrious Logo" width={130} />
          </Link>
          <div className="flex flex-wrap justify-center gap-5 text-sm text-center gap-y-4 lg:items-end">
            <Link
              className="hover:underline"
              href="/site-portal-terms"
              data-testid="link-terms"
            >
              Website & Portal Terms
            </Link>
            <Link
              className="hover:underline"
              href="/privacy-policy"
              data-testid="link-privacy"
            >
              Online Privacy Policy
            </Link>
            <div data-testid="copyrights">
              © 2023 Industrious. All rights reserved.
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
