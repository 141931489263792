import clsx from 'clsx';

type Props = {
  className?: string;
};

export default function SmallIndustriousLogo(props: Props) {
  const { className } = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="27"
      fill="none"
      role="img"
      className={clsx('max-sm:w-5', className || 'fill-teal-5')}
      viewBox="0 0 22 27"
    >
      <title>Logo Industrious</title>
      <path
        fillRule="evenodd"
        d="m15.588 1.64-.649.83-.556.716a4.943 4.943 0 0 0-1.991-.725l.292-.375.648-.83.649-.836c.04-.056.083-.11.126-.163.066-.083.133-.166.189-.257l.009.001c.344.041.69.082 1.126.196.212.367.34.67.465.97l.026.061a2.75 2.75 0 0 0-.225.27c-.036.048-.072.095-.11.141Zm1.352 8.59c1.098.412 3.252 2.314 4.243 4.414 1.163 2.465.76 7.544.707 8.06l-.092 1.469h-3.534c-2.598 0-3.4-2.541-3.651-4.404L10.994 27l-3.608-7.23c-.25 1.862-1.053 4.403-3.651 4.403H.2l-.093-1.388c-.05-.516-.453-5.676.709-8.141.991-2.1 3.145-4.002 4.243-4.413.17-4.452 2.824-6.119 5.404-6.119H11.535c2.58 0 5.234 1.667 5.405 6.119ZM5.404 17.264c0 2.817-.502 4.402-1.669 4.402H2.202c-.074-1.755-.042-4.525.584-5.849.473-1.004 1.202-2.159 1.832-2.757l.787 4.2v.001l-.001.001v.002ZM11.006 6.37h-.541c-1.483 0-2.978.752-3.303 3.51h7.676c-.324-2.758-1.82-3.51-3.302-3.51h-.53ZM11 22.07l-1.647-3.163h3.295L11 22.07Zm3.421-6.172.427-3.01H7.152l.426 3.01h6.842Zm5.377 5.768h-1.533c-1.167 0-1.669-1.585-1.669-4.402v-.006l.787-4.2c.63.599 1.36 1.753 1.832 2.758.625 1.324.657 4.094.583 5.85ZM7.617 3.186l-.56-.716-.65-.83c-.04-.05-.076-.098-.113-.148a2.576 2.576 0 0 0-.216-.264c.13-.32.26-.639.487-1.031C7.005.083 7.35.042 7.694 0L7.704 0c.062.1.134.19.206.28l.11.14.648.835.644.831.296.375c-.69.09-1.37.333-1.991.725Z"
        clipRule="evenodd"
      />
    </svg>
  );
}
