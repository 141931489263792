import { ReactNode } from 'react';

type ListTitleProps = {
  children: ReactNode;
};

export default function ListTitle(props: ListTitleProps) {
  const { children } = props;
  return <h3 className="font-medium uppercase">{children}</h3>;
}
