'use client';

import { useEffect } from 'react';

import StaticPageError from '@/components/error-pages/StaticPageError';
import { dmSansFont } from '@/theme/dmFont';
import { DEFAULT_LOCALE } from '@/utils';
import { captureException } from '@/utils/sentry';

export default function GlobalError({
  error,
}: {
  error: Error & { digest?: string };
}) {
  useEffect(() => {
    captureException(error.message);
  }, [error]);

  return (
    // global-error must include html and body tags
    <html
      lang={DEFAULT_LOCALE}
      className={`${dmSansFont.variable} min-h-screen`}
    >
      <body>
        <StaticPageError />
      </body>
    </html>
  );
}
