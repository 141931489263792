import Link from 'next/link';

import List from '../FooterSC/List';
import Title from '../FooterSC/ListTitle';
import Section from '../FooterSC/Section';

type LinksSectionProps = {
  title: string;
  items: {
    href: string;
    dataTestId: string;
    label: string;
    isExternal?: boolean;
  }[];
};

export default function StaticLinksSection({
  title,
  items,
}: LinksSectionProps) {
  return (
    <Section>
      <Title>{title}</Title>
      <List>
        {items.map((item) => (
          <li key={item.dataTestId}>
            <Link
              href={item.href}
              data-testid={item.dataTestId}
              className="font-light hover:underline"
              rel={item.isExternal ? 'noopener' : ''}
              target={item.isExternal ? '_blank' : '_self'}
            >
              {item.label}
            </Link>
          </li>
        ))}
      </List>
    </Section>
  );
}
