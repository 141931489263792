import Link from 'next/link';

import { US_GENERIC_NUMBER } from '@/utils/constants';

import { ClickablePhoneNumber } from '../ClickablePhoneNumber';
import LargeIndustriousLogo from '../Header/LargeIndustriousLogo';
import SmallIndustriousLogo from '../Header/SmallIndustriousLogo';
import ErrorContent from './ErrorContent';
import StaticFooter from './StaticFooter';

export default function StaticPageError() {
  const description = (
    <>
      Please refresh the page and try again. If the problem persists, you can{' '}
      <Link className="underline" href="/contact-us">
        contact us
      </Link>{' '}
      or call us at <ClickablePhoneNumber phoneNumber={US_GENERIC_NUMBER} />.
      Monday-Friday, 8am-5pm.
    </>
  );

  return (
    <>
      <div className="sticky h-[48px] sm:h-[70px]  top-0 inset-x-0 z-10">
        <div className="relative w-full h-full bg-white border-b border-b-grey-90">
          <div className="flex items-center justify-between h-full base-container">
            <div className="flex items-center h-full">
              <Link className="md:hidden" href="/" data-testid="nav-logo">
                <SmallIndustriousLogo />
              </Link>
              <Link className="max-md:hidden" href="/" data-testid="nav-logo">
                <LargeIndustriousLogo />
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="py-5 pt-7 sm:py-8 sm:pt-12 base-container">
        <ErrorContent
          statusCode={500}
          title="Sorry, something went wrong."
          description={description}
          ctaLabel="Refresh"
          altText="Desk with spilled coffee"
        />
      </div>
      <StaticFooter />
    </>
  );
}
